import React from 'react';
import styles from './CustomCheckbox.module.css';

  const CustomCheckbox = ({label, name, onChange, checked, disabled, width}) => {
      
      return <>
            <div className={name === 'consent' ? styles.rowConsent : styles.row}>
                <div className={styles.labelText}>{label.toUpperCase()}</div>
                <div><label className={styles.switch}><input disabled={disabled} id={name} name={name} type="checkbox" checked={checked || false} onChange={onChange}/><span className={styles.slider}></span></label></div>
                </div>
        </>
      
  }

  export default CustomCheckbox;