export const getUrlParams = () => {
    const urlParams = new URLSearchParams(window.location.search);
    return urlParams.get('key');
};

const ENV = {
    localhost: 'https://chaumet--dev.sandbox.my.salesforce-sites.com',
    dev: 'https://chaumet--dev.sandbox.my.salesforce-sites.com',
    full: 'https://chaumet--full.sandbox.my.site.com',
    prod: 'https://subscription.chaumet.com',
  }
  
  export const getEnvUrl = () => {
    const baseUrl = window.location.hostname
    if (baseUrl.includes('localhost')) {
      return ENV.localhost
    } else if (baseUrl.includes('dev')) {
      return ENV.dev
    } else if (baseUrl.includes('full')) {
      return ENV.full
    } else {
      return ENV.prod
    }
  }

  export const getTranslationUrl = () => {
    const baseUrl = window.location.hostname
    if (baseUrl.includes('localhost')) {
      return ENV.localhost
    } else if (baseUrl.includes('dev')) {
      return ENV.dev
    } else if (baseUrl.includes('full')) {
      return ENV.full
    } else {
      return ENV.prod
    }
  }

export const setLngLocalStorage = lng => {
    localStorage.setItem('lng', lng);
}

export const getLngLocalStorage = () => {
    return localStorage.getItem('lng');
}

