import React, { useEffect, useState, useCallback } from 'react';
import { getClientOptin, updateClientOptins } from '../services/salesforce/index';
import Optins from '../components/Optins';
import { getUrlParams, setLngLocalStorage } from '../utils/Utils';
import Loading from '../components/Loading';
import Footer from '../components/Footer';
import SessionExpired from '../components/SessionExpired';
import styles from './index.module.css';
import UpdateOptinSuccess from '../components/UpdateOptinSuccess';
import { useTranslation } from 'react-i18next';

  const Home = () => {

    const { t, i18n } = useTranslation();
    const [isLoading, setIsLoading] = useState(true);
    const [clientId, setClienId] = useState('');
    const [clientLanguage, setClientLanguage] = useState('en_US');
    const [sourceChannel, setSourceChannel] = useState('4');
    const [optinList, setOptinList] = useState({});
    const [errorMsg, setErrorMsg] = useState('Error');
    const [displayErrorMessage, setDisplayErrorMessage] = useState(false);
    const [toggleDesable, setToggleDesable] = useState(true);
    const [isSessionExpired, setIsSessionExpired] = useState(true);
    const [isUpdateOptinDone, setIsUpdateOptinDone] = useState(false);
    const [isOptinsBlockHiden, setIsOptinsBlockHiden] = useState(false);
    const [policyChecked, setPolicyChecked] = useState(false);
    const [returnUrl, setReturnUrl] = useState("");
    const [consentCGI, setConsentCGI] = useState(null);

    const changeAppLanguage = useCallback(language => {
      setLanguage(language);
      i18n.changeLanguage(language);
  }, [i18n]);

    const init = useCallback(async () => {
      setIsLoading(true);
      const queryString = window.location.search;
      const key = getUrlParams(queryString);
      const response = await getClientOptin(key);
      if (response.success === true && response.data?.length > 0) {
        if (response.data[0].status === 'tokenValid') {
        setIsSessionExpired(false);
        setClienId(response.data[0].clientId);
        changeAppLanguage(response.data[0].language);
        setLngLocalStorage(response.data[0].language);
        setConsentCGI(response.data[0].consentCGI);
        if (response.data[0].returnUrl !== undefined) {
          setReturnUrl(response.data[0].returnUrl);
        }
        if (response.data[0].sourceChannel !== undefined) {
          setSourceChannel(response.data[0].sourceChannel);
        }
          if (response.data[0].consent === false) {
            setOptinList({
              consent: false,
              email: false,
              newsletter: false,
              mobile: false,
              instantMessaging: false,
              postal: false});
          }
          else{
            setOptinList(response.data[0]);
            setIsOptinsBlockHiden(true);
          }
      }
      else{
        setIsSessionExpired(true);
        setToggleDesable(true);
      }
    }
      setIsLoading(false);
    }, [changeAppLanguage]);

    useEffect(() => {
        init();
    }, [init]);

    useEffect(() => {
        const {consent, email, instantMessaging, mobile, newsletter, postal} = optinList;
        if (consent === true && email === false && newsletter === false  && mobile === false && instantMessaging === false  && postal === false) {
            setOptinList(prevState => ({
                ...prevState,
                consent: false
            }));
        }
        else if (consent === false && email === false && newsletter === false  && mobile === false && instantMessaging === false  && postal === false) {
            setToggleDesable(true);
        }
        else{
            setToggleDesable(false);
        }

        if (consent === false) {
          setToggleDesable(true);
        }
    }, [optinList]);

    const updateConsentCGI = value => {
      setConsentCGI(value);
    };

    const updatePolicyChecked = () => {
      setPolicyChecked(!policyChecked);
    };

      const handleConsentChange = (isConsentChecked) => {
        if(isConsentChecked === true) {
            setOptinList({
            email: true,
            newsletter: true,
            mobile: true,
            instantMessaging: true,
            postal: true,
            consent: true});
            setToggleDesable(false);
            setIsOptinsBlockHiden(true);
        }
        else{
            setOptinList({
                email: false,
                newsletter: false,
                mobile: false,
                instantMessaging: false,
                postal: false,
                consent: false});
                setIsOptinsBlockHiden(false);
        }
      };
    
      const handleToggleChange = (event) => {
        const { name, checked } = event.target;
        setOptinList(prevState => ({
            ...prevState,
            [name]: checked
        }));
        };

      const handleUpdateClientOptins = async (event) => { 
        setDisplayErrorMessage(false);
        if (!policyChecked) {
          setErrorMsg(t("The Privacy Policy Acceptance is mandatory"))
          setDisplayErrorMessage(true);
          return
        }
        if (optinList.consent === null || optinList.consent === undefined) {
          setErrorMsg(t("The consent Marketing Checkbox is mandatory"))
          setDisplayErrorMessage(true);
          return
        }
        if (consentCGI === null || consentCGI === undefined) {
          setErrorMsg(t("The consent CGI Checkbox is mandatory"))
          setDisplayErrorMessage(true);
          return
        }
        setIsLoading(true);
        event.preventDefault();
        let data = optinList;
        const pair = {clientId: clientId, sourceChannel: sourceChannel, consentCGI: consentCGI};
        data = {...data, ...pair};
        const res = await updateClientOptins(data);
        setIsLoading(false);
        if (res.success === true){
          setIsUpdateOptinDone(true);
        }
        else{
          setErrorMsg(t('Error when trying to save your preferences. Please retry later.'))
          setDisplayErrorMessage(true);
        }
      };

      const setLanguage = lng => {
        setClientLanguage(lng);
        setLngLocalStorage(lng);
      };
      
      return <>
        <div className={clientLanguage === "ar" ? styles.containerRTL : styles.containerLTR}>
          <div className={styles.headerContent}>
            <div><a href="https://www.chaumet.com" ><img src="assets/logoMain.png" alt="Chaumet" className={styles.logo}></img></a></div>
          </div>
          <div className={styles.bodyContent}>
            {/* {sessionId} */}
            {isLoading ? <Loading/> : !isSessionExpired && !isUpdateOptinDone ? <Optins optinList={optinList} toggleDesable={toggleDesable} isOptinsBlockHiden={isOptinsBlockHiden} policyChecked={policyChecked} errorMsg={errorMsg} clientLanguage={clientLanguage} handleConsentChange={handleConsentChange} handleToggleChange={handleToggleChange} consentCGI={consentCGI} handleUpdateClientOptins={handleUpdateClientOptins} displayErrorMessage={displayErrorMessage} updatePolicyChecked={updatePolicyChecked} updateConsentCGI={updateConsentCGI}/> : !isSessionExpired && isUpdateOptinDone ? <UpdateOptinSuccess returnUrl={returnUrl} /> : <SessionExpired />}
          </div>
          </div>
          <div>
            <Footer changeAppLanguage={changeAppLanguage} clientLanguage={clientLanguage}/> 
            </div>
        </>
      ;
  }

  export default Home;