import { salesforce_instance } from './salesforce';

export const getClientOptin = async (id) => {
    return await salesforce_instance.get({ url: `/Optins?key=${id}`});
}

export const updateClientOptins = async (body) => {
    return await salesforce_instance.post(
        {
            url: `/Optins`,
            body: body
        }
    )
}