import React, { useEffect, useState } from 'react';
import styles from './Optins.module.css';
import CustomCheckbox from '../UI/CustomCheckbox';
import { useTranslation } from 'react-i18next';
import checked from './../styles/Images/checked.png';
import unchecked from './../styles/Images/unchecked.png';

  const Optins = ({optinList, toggleDesable, handleConsentChange, handleToggleChange, handleUpdateClientOptins, displayErrorMessage, isOptinsBlockHiden, policyChecked, updatePolicyChecked, errorMsg, clientLanguage, consentCGI, updateConsentCGI}) => {
    const { t } = useTranslation();
    const {consent, email, instantMessaging, mobile, newsletter, postal} = optinList;
    const privacyPolicyUrls = {
        fr: "https://www.chaumet.com/fr_fr/donnees-personnelles",
        en_US: "https://www.chaumet.com/fr_en/privacy-policy",
        ja: "https://www.chaumet.com/jp_ja/privacy-policy",
        ko: "https://www.chaumet.com/kr_kr/privacy-policy",
        zh_CN: "https://www.chaumet.cn/cn_zh/privacy-policy",
        zh_TW: "https://www.chaumet.com/hk_cht/privacy-policy",
        zh_Hant: "https://www.chaumet.com/tw_cht/privacy-policy",
        ar: "https://www.chaumet.com/ae_ar/privacy-policy",
        default: "https://www.chaumet.com/fr_en/privacy-policy"
        }
    let privacyPolicyUrl = privacyPolicyUrls[clientLanguage] || privacyPolicyUrls.default;
    const title = t('My contact preferences');
    const consentCGIDescription = t('I agree to the processing of my personal data in order to create my profile and personalise my experience, based on my preferences and purchasing data, which may be transmitted to LVMH, our parent company');
    const consentMarketingDescription = t('I would like to receive communications from Chaumet regarding its creations, services and events.');
    const saveButtonText = t('Save');
    const [selectedPrivacyLink, setSelectedPrivacyLink] = useState('https://www.chaumet.com/en/privacy-policy');

    const listCheckboxOptins = [
        {
            label: t("Newsletter"),
            name:"newsletter",
            onChange:handleToggleChange,
            checked: newsletter,
            disabled: toggleDesable
        },
        {
            label: t("Personalised e-mail from a client advisor"),
            name:"email",
            onChange:handleToggleChange,
            checked: email,
            disabled: toggleDesable
        },
        {
            label: t("Phone and/or SMS"),
            name:"mobile",
            onChange:handleToggleChange,
            checked: mobile,
            disabled: toggleDesable
        },
        {
            label: t("Post mail"),
            name:"postal",
            onChange:handleToggleChange,
            checked: postal,
            disabled: toggleDesable
        },
        {
            label: t("Social media platforms and instant messaging"),
            name:"instantMessaging",
            onChange:handleToggleChange,
            checked: instantMessaging,
            disabled: toggleDesable
        }
    ];

    useEffect(() => {
        setCurrentPrivacyLink();
    }, [clientLanguage]);

    const setCurrentPrivacyLink = () => {
        setSelectedPrivacyLink(privacyPolicyUrls[clientLanguage]);
    };

    return <>
        <h2 className={styles.title}>{title.toUpperCase()}</h2>
        <div className={styles.container}>
        <div className={styles.containerHeader}>
        <div><p className={styles.consentDescription}>{consentCGIDescription.toUpperCase()} <a href={privacyPolicyUrl} target="_blank" rel="noreferrer">{t('[READ MORE]')}.</a></p></div>
        <div className={clientLanguage === "ar" ? styles.containerHeaderInputLeftRTL : styles.containerHeaderInputLeftLTR} onClick={() => updateConsentCGI(true)}>
        <img src={consentCGI === true ? checked : unchecked} alt="consent CGI Checked" className={clientLanguage === "ar" ? styles.checkBoxIconRTL : styles.checkBoxIconLTR}></img>
            <span>{t('Yes')}</span>
        </div>
        <div className={clientLanguage === "ar" ? styles.containerHeaderInputRightRTL : styles.containerHeaderInputRightLTR} onClick={() => updateConsentCGI(false)}>
            <img src={consentCGI === false ? checked : unchecked} alt="consent CGI unchecked" className={clientLanguage === "ar" ? styles.checkBoxIconRTL : styles.checkBoxIconLTR}></img>
            <span>{t('No')}</span>
        </div>
        </div>
        <div className={styles.containerHeader}>
        <div><p className={styles.consentDescription}>{consentMarketingDescription.toUpperCase()}</p></div>
            <div className={clientLanguage === "ar" ? styles.containerHeaderInputLeftRTL : styles.containerHeaderInputLeftLTR} onClick={() => handleConsentChange(!consent)}>
                <img src={consent ? checked : unchecked} alt="consent Marketing" className={clientLanguage === "ar" ? styles.checkBoxIconRTL : styles.checkBoxIconLTR}></img>
                <span>{t('Yes')}</span>
            </div>
            <div className={clientLanguage === "ar" ? styles.containerHeaderInputRightRTL : styles.containerHeaderInputRightLTR} onClick={() => handleConsentChange(!consent)}>
                <img src={consent ? unchecked : checked} alt="consent Marketing" className={clientLanguage === "ar" ? styles.checkBoxIconRTL : styles.checkBoxIconLTR}></img>
                <span>{t('No')}</span>
            </div>
        </div>
        </div>
        {isOptinsBlockHiden && <div className={styles.optinsBlock}>
            <div className={styles.optinsList}>{listCheckboxOptins.map((el, key) => (<CustomCheckbox {...el} key={key} />))}</div>
           </div>}
        <div className={styles.policyCheckboxContainer} >
            <div className={styles.consentCheckboxContainer}><input className={styles.consentCheckbox} id="policy" name="policy" type="checkbox" checked={policyChecked || false} onChange={updatePolicyChecked}/></div>
            <div><div><p className={styles.consentDescription}>{t('I have read and understood the').toUpperCase()} {selectedPrivacyLink && <a href={selectedPrivacyLink} target="_blank" className={styles.privacyPolicy} rel="noreferrer">{t('Privacy Policy').toUpperCase()}</a>} {t('and I declare that I am over 16 years of age.').toUpperCase()}</p></div>
            {displayErrorMessage && <div className={styles.errorMessageBlock}><p className={styles.errorMessage}>{errorMsg}</p></div>}
            </div>
        </div>
        <button type="submit" className={styles.saveButton} onClick={handleUpdateClientOptins}>{saveButtonText.toUpperCase()}</button>
    </>
  }

  export default React.memo(Optins);