import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import './styles/index.scss';
import './translation/i18n';

import App from './pages';
// import Loading from './components/Loading';

ReactDOM.render(
  <React.StrictMode>
    {/* <Suspense fallback={<Loading />}> */}
      <App />
    {/* </Suspense> */}
  </React.StrictMode>,
  document.getElementById('root')
);
