import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import XHR from 'i18next-xhr-backend';
import { getTranslationUrl, getLngLocalStorage } from '../utils/Utils';
import { getAccessToken } from '../utils/auth';

// FOR DEV ONLY
let accessToken;
if (window && window.location && window.location.hostname === "localhost") {
  accessToken = getAccessToken();
} else {
  // accessToken = sessionId;
}

i18n
  .use(initReactI18next) 
  .use(XHR)
  .init({
    // lng: 'en_US',
    // fallbackLng: 'en_US',
    interpolation: { escapeValue: false },
    htmlTag: document.documentElement,
    defaultNS: ['common'],
    fallbackNS: ['common'],
    ns: ['common'],
    detection: {
        htmlTag: document.documentElement
    },
    backend: {
        loadPath: () => {
            const lng = getLngLocalStorage();
            if (!lng) return;
             return `${getTranslationUrl()}/services/apexrest/ddc/translationDdc?app=Data Capture&format=json&lng=${lng}`;
        },
        requestOptions: {
            cache: 'no-store',
        },
        customHeaders: {
            // authorization: 'Bearer ' + accessToken
        }
    },
    react: {
        wait: true,
        useSuspense: false
    }
});

  export default i18n;