import React from 'react';
import styles from './UpdateOptinSuccess.module.css';
import { useTranslation } from 'react-i18next';

  const UpdateOptinSuccess = ({returnUrl}) => {

    const { t } = useTranslation();
    const successLabel = t("Return to website");
      
      return <>
            <div className={styles.container}>
              <div><p className={styles.title}>{t("Thank you")}</p></div>
              <div><p className={styles.description}>{t("We have saved your contact preferences")}</p></div>
              {returnUrl && <div className={styles.btnContainer}><a href={'https://' + returnUrl} className={styles.btn} type="submit">{successLabel.toUpperCase()}</a></div>}
            </div>
        </>
      
  }

  export default React.memo(UpdateOptinSuccess);